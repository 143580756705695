<template lang="pug">
//- t-card(v-on="$listeners" style="min-height:500px" )
t-window(v-bind="$attrs" v-on="$listeners" min-height="500px")
  template(slot="header") Контакты
  template(slot="header-actions"): span

  v-card-text
    t-input(label="Телефон" placeholder="(777) 701 XX XX " autofocus type="number" v-model="phone" :hints="['701','771','777','708']")
    //- v-text-field(label="Телефон" placeholder="(777) 701 XX XX " autofocus Zsolo type="number" clearable hint="yes" flat v-model="phone" outlined)
       template(v-slot:message="{ message, key }")
        v-tabs.pt-0(Zfixed-tabs dense grow)
          v-tab.pr-0.pl-1: v-btn(block color="primary" outlined) 701
          v-tab.pr-0.pl-1: v-btn(block color="primary" outlined) 777
          v-tab.pr-0.pl-1: v-btn(block color="primary" outlined) 707
    v-text-field(placeholder="Клиент Иванов Иван"  hide-details="auto" solo flat v-model="name" outlined)

  t-collapse(v-model="showExtra" hide)
    template(slot="activator")
      t-list(color="transparent")
        t-list-item.grey--text(icon="mdi-chevron-down" title="Еще ..." no-right @click="showExtra = !showExtra")
    v-divider.my-2
    v-card-text
      v-text-field(placeholder="Email" solo flat v-model="email" outlined)
      v-text-field(placeholder="Адрес" solo flat v-model="address" outlined)

  template(slot="footer"): t-btn(@click="addClient({phone,name}); $emit('input',false)") Добавить

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    // Basket: () => import('../Basket.vue'),
    // CartItem: () => import('../CartItem.vue'),
  },
  props: {
  },

  data: () => ({
    name: null,
    address: null,
    phone: null,
    email: null,
    showExtra: false,
  }),
  computed: {
    ...mapState({

    }),

  },
  methods: {
    ...mapActions({
      addClient: 'tjtheme/addClient',
    }),
  },
}
</script>
<style lang="stylus">

</style>
